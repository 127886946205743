import React from "react";
import PropTypes from "prop-types";
import SelectController from "./SelectHardwareController"; // Import the generalized component
import shouldDisplayPricing from "../../user/configurations/constraints";

function SelectCPUController({ cpuOptions, onChange, featureId, value }) {
    return (
        <div data-testid="cpu-select">
            <SelectController
                optionsData={cpuOptions}
                onChange={onChange}
                featureId={featureId}
                value={value}
                labelText="Number of CPUs"
                unitText="CPUs"
                shouldDisplayPricePerHour={(hasApps, val) =>
                    shouldDisplayPricing("cpu", hasApps, val)
                }
            />
        </div>
    );
}

SelectCPUController.propTypes = {
    cpuOptions: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
        )
    ),
    onChange: PropTypes.func.isRequired,
    featureId: PropTypes.string.isRequired,
    value: PropTypes.string,
};

SelectCPUController.defaultProps = {
    cpuOptions: [["2", false]], // default CPU options
    value: undefined,
};

export default SelectCPUController;
