import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthorizedPage from "./routes/pages/authorized/AuthorizedPage";
import { AppContextProvider } from "./context/AppContext";
import CreateApplication from "./features/applications/CreateApplication";
import Settings from "./features/settings/Settings";
import AuthorizedRoute from "./routes/pages/AuthorizedRoute";
import NavigateWithParams from "./routes/pages/NavigateWithParams";
import { ApplicationSettingsProvider } from "./features/applications/ApplicationSettingsContext";

import {
    RegisterPage,
    SigninPage,
    VerifyPage,
    PasswordRecoveryPage,
    SocialLoginProxyPage,
    ProjectRecoveryPage,
} from "./routes/pages/public";

// fixes current style gaps. delete later?
import "./styles/css/global.css";
import { ApplicationStatus, Applications } from "./features/applications";
import PublicRoute from "./routes/pages/PublicRoute";
import ApplicationSettings from "./features/applications/ApplicationSettings";
import ProductTour from "./utils/ProductTour";
import { ProjectContextProvider } from "./context/ProjectContext";
import { captureOnboardingUrlParam } from "./utils/CustomOnboarding";

const theme = createTheme({
    app: {
        drawerWidth: 240,
        closedDrawerWidth: 60,
        stepsDrawerWidth: 240,
    },
    fonts: {
        size: {
            small: "0.75rem", // 12px
            medium: "0.875rem", // 14px
        },
        weight: {
            semiBold: 600,
            bold: 700,
        },
    },
    palette: {
        primary: {
            main: "#000",
            danger: "#d32f2f",
            success: "#2e7d32",
        },
        secondary: {
            main: "#11cb5f",
        },
        neutral: {
            white: "#fff",
            grey: "#c7c7c7",
            darkGrey: "#bdbdbd",
            lightGrey: "#F2F2F2",
            black: "#000",
            link: "#1565c0",
        },
    },
});

function App() {
    captureOnboardingUrlParam();

    return (
        <ThemeProvider theme={theme}>
            <AppContextProvider>
                <ProductTour />

                <Routes>
                    {/* PUBLIC ROUTES */}
                    <Route
                        exact
                        path="/signin"
                        element={
                            <PublicRoute>
                                <SigninPage />
                            </PublicRoute>
                        }
                    />

                    <Route
                        exact
                        path="/register"
                        element={
                            <PublicRoute>
                                <RegisterPage />
                            </PublicRoute>
                        }
                    />
                    <Route
                        exact
                        path="/verify"
                        element={
                            <PublicRoute>
                                <VerifyPage />
                            </PublicRoute>
                        }
                    />

                    <Route
                        exact
                        path="/forgot-password"
                        element={
                            <PublicRoute>
                                <PasswordRecoveryPage />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/relaunch-application/"
                        element={<ProjectRecoveryPage />}
                    />
                    <Route
                        exact
                        path="/social-login-proxy"
                        element={
                            <PublicRoute>
                                <SocialLoginProxyPage />
                            </PublicRoute>
                        }
                    />

                    {/* AUTHORIZED ROUTES */}
                    <Route
                        exact
                        path="/"
                        element={<NavigateWithParams to="/applications" />}
                    />
                    <Route
                        path="/"
                        element={
                            <AuthorizedRoute>
                                <AuthorizedPage />
                            </AuthorizedRoute>
                        }
                    >
                        <Route path="applications" element={<Applications />} />

                        <Route
                            path="applications/create"
                            element={
                                <ProjectContextProvider>
                                    <CreateApplication />
                                </ProjectContextProvider>
                            }
                        />

                        <Route
                            path="applications/:projectId/:jobId"
                            element={<ApplicationStatus />}
                        />

                        <Route
                            path="applications/:projectId/:jobId/settings"
                            element={
                                <ApplicationSettingsProvider>
                                    <ApplicationSettings />
                                </ApplicationSettingsProvider>
                            }
                        />

                        <Route path="account" element={<Settings />} />
                    </Route>
                    <Route
                        path="*"
                        element={
                            <NavigateWithParams to="/applications" replace />
                        }
                    />
                </Routes>
            </AppContextProvider>
        </ThemeProvider>
    );
}

export default App;
