import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
} from "@mui/material";
import { AppContext } from "../../../context/AppContext";
import ploomberAPI from "../../../services/ploomberAPI.ts";
import ActionButton from "../../../components/UI/ActionButton";
import CustomDomainInput from "../../applications/controllers/customDomainConroller/CustomDomainInput";
import { parseErrorMessage } from "../../../utils/utils.ts";

function NewCustomDomainDialog({ open, onCreate, onClose, registeredDomains }) {
    const [canCreate, setCanCreate] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const { updateSnackbarStatus } = useContext(AppContext);
    const [domainToRegister, setDomainToRegister] = useState("");

    const handleChangeDomainInput = (newValue) => {
        setDomainToRegister(newValue);
        setCanCreate(true);
    };

    const handleCreateCustomDomainClick = () => {
        setIsCreating(true);

        ploomberAPI
            .registerNewCustomDomain(domainToRegister)
            .then(async () => {
                setDomainToRegister("");
            })
            .catch((err) => {
                updateSnackbarStatus({
                    severity: "error",
                    message: parseErrorMessage(err),
                });
            })
            .finally(() => {
                setIsCreating(false);
                onCreate();
            });
    };

    const handleCustomDomainInputError = (e) => {
        setCanCreate(false);
    };

    return (
        <Dialog
            id="newCustomDomainDialog"
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            style={{
                height: "60%",
            }}
        >
            <DialogTitle id="responsive-dialog-title">New domain</DialogTitle>

            <DialogContent>
                <CustomDomainInput
                    disabled={false}
                    domains={registeredDomains}
                    label="Domain Name"
                    onChange={handleChangeDomainInput}
                    value={domainToRegister}
                    onValidationError={handleCustomDomainInputError}
                />
            </DialogContent>
            <DialogActions>
                <ActionButton
                    variant="outline"
                    id="closeDialogButton"
                    onClick={onClose}
                >
                    Cancel
                </ActionButton>

                <ActionButton
                    id="createCustomDomainButton"
                    onClick={handleCreateCustomDomainClick}
                    variant="contained"
                    disabled={!canCreate || isCreating}
                    disabledOptions={
                        isCreating
                            ? {
                                  startIcon: (
                                      <CircularProgress
                                          size={10}
                                          color="neutral"
                                      />
                                  ),
                                  text: "Creating...",
                              }
                            : {}
                    }
                >
                    Create
                </ActionButton>
            </DialogActions>
        </Dialog>
    );
}

NewCustomDomainDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onCreate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    registeredDomains: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default NewCustomDomainDialog;
