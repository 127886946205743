import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/AddBox";
import { BlockTable } from "../../../components/BlockTable";
import Input from "../../../components/UI/Form/inputs/Input";
import KeyValWrapper from "../../../styles/components/KeyValWrapper.Styled";

function KeyValuePair({
    index,
    mkey,
    mvalue,
    disabled,
    onUpdate,
    onRemoveKey,
}) {
    const [value, setValue] = useState(mvalue);
    const [key, setKey] = useState(mkey);

    useEffect(() => {
        setKey(mkey);
        setValue(mvalue);
    }, [mkey, mvalue]);

    const handleUpdate = () => {
        if (value.length > 0) {
            onUpdate(key, value);
        }
    };

    return (
        <KeyValWrapper key={index}>
            <div style={{ flex: 1 }}>
                <div
                    style={{
                        marginBottom: 5,
                    }}
                >
                    <div className="label">Key:</div>
                </div>
                <Input
                    onBlur={handleUpdate}
                    id={`inputKey${index}`}
                    testid-data="InputKeyData"
                    value={key}
                    placeholder="Enter Your Key"
                    onChange={(e) => {
                        setKey(e.target.value);
                    }}
                    disabled={disabled} // Disable input for previous keys
                />
            </div>
            <div style={{ flex: 1 }}>
                <div
                    style={{
                        marginBottom: 5,
                    }}
                >
                    <div className="label">Value:</div>
                </div>
                <Input
                    onBlur={handleUpdate}
                    id={`inputValue${index}`}
                    value={disabled ? "●●●●●●" : value}
                    placeholder={disabled ? "●●●●●●" : "Enter Your Value"}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    disabled={disabled} // Disable input for previous keys
                />
            </div>
            <DeleteIcon
                onClick={() => {
                    onRemoveKey(index);
                }}
                data-testid={`removeEnvVar${index}`}
                style={{
                    marginRight: 25,
                    marginTop: 20,
                    fontSize: 25,
                }}
            />
        </KeyValWrapper>
    );
}

KeyValuePair.propTypes = {
    mkey: PropTypes.string.isRequired,
    mvalue: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onRemoveKey: PropTypes.func.isRequired,
};

function SetSecretsController({
    envVariables,
    onNewField,
    onChange,
    onSecretUpdate,
    onRemoveKey,
}) {
    const addInputField = () => {
        onNewField();
    };

    return (
        <BlockTable
            keys={[
                {
                    style: {
                        padding: 0,
                    },
                },
            ]}
            values={[
                [
                    <div>
                        <div className="label">Secrets:</div>
                        {envVariables.map((inputField, index) => (
                            <KeyValuePair
                                key={index}
                                disabled={
                                    inputField.previous ||
                                    Boolean(
                                        inputField.key &&
                                            inputField.key.length > 0 &&
                                            inputField.value &&
                                            inputField.value.length > 0
                                    )
                                }
                                onRemoveKey={onRemoveKey}
                                mkey={inputField.key}
                                mvalue={inputField.value}
                                index={index}
                                onUpdate={onSecretUpdate}
                            />
                        ))}
                        <AddIcon
                            className="cursor-pointer"
                            type="button"
                            onClick={addInputField}
                            data-testid="addAnotherEnvVar"
                            style={{ fontSize: 25 }}
                        />
                    </div>,
                ],
            ]}
        />
    );
}

SetSecretsController.propTypes = {
    envVariables: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.string,
        })
    ).isRequired,
    onNewField: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onSecretUpdate: PropTypes.func.isRequired,
    onRemoveKey: PropTypes.func.isRequired,
};

SetSecretsController.defaultProps = {
    onChange: undefined,
};

export default SetSecretsController;
