import { styled } from "@mui/material/styles";
import TerminalError from "../../components/TerminalError";

const StyledTerminalError = styled(TerminalError, {
    shouldForwardProp: (prop) => prop === "message",
})(() => ({
    "div.TerminalErrorContainer": {
        backgroundColor: "#feebe8",
        padding: "1rem",
        fontSize: "1rem",
        fontWeight: "400",
        marginBottom: "20px",
        borderLeft: "0.25rem solid #d32f2f",
    },
    "div.TerminalErrorContainer2": {
        display: "inline-flex",
        alignItems: "center",
    },
    "div.TerminalErrorContainer3": {
        marginRight: "1rem",
        display: "flex",
        alignItems: "center",
    },
}));

export default StyledTerminalError;
