import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useDropzone } from "react-dropzone";
import React, { useState, useCallback } from "react";
import {
    StyledDropzone,
    StyledFileList,
} from "../styles/components/UploadBox.Styled";

/**
 * FileDropzone component: A drag-and-drop area for file uploads.
 * @param {string} accept - The types of files accepted.
 * @param {string} id - The ID for the input element
 * @param {string} dataTestId - Test identifier for the component.
 * @param {React.ReactNode} label - Label displayed in the dropzone.
 * @param {object} style - Custom styles for the dropzone.
 * @returns {JSX.Element} The rendered FileDropzone component.
 */
const FileDropzone = function ({
    accept,
    id,
    dataTestId,
    onDrop: propOnChange,
    droppedFiles,
    label,
    reset,
}) {
    const removeAll = () => {
        reset();
    };
    const { getRootProps, getInputProps } = useDropzone({
        accept,
        onDrop: (files) => {
            propOnChange(files);
        },
    });

    // Showing the list of accepted files
    // and file sizes in MB with 3 digits, rounded to larger value
    const fileList = droppedFiles.map((file) => (
        <li key={file.path}>
            {file.path} -{" "}
            {(Math.ceil((file.size / (1024 * 1024)) * 1000) / 1000).toFixed(3)}
            MB
        </li>
    ));

    return (
        <StyledDropzone {...getRootProps()}>
            <input {...getInputProps()} id={id} data-testid={dataTestId} />
            <div className="label">{label}</div>
            <p>Drag and drop a file here, or click to select a file</p>
            <aside>
                <StyledFileList>{fileList}</StyledFileList>
            </aside>
            {droppedFiles.length > 0 && (
                <button
                    type="button"
                    onClick={(e) => {
                        e.stopPropagation();
                        removeAll();
                    }}
                    data-testid="removeAll-button"
                >
                    Remove All
                </button>
            )}
        </StyledDropzone>
    );
};

FileDropzone.propTypes = {
    accept: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
    id: PropTypes.string.isRequired,
    dataTestId: PropTypes.string.isRequired,
    onDrop: PropTypes.func.isRequired,
    label: PropTypes.node.isRequired,
    maxFiles: PropTypes.number.isRequired,
    droppedFiles: PropTypes.arrayOf((props, propName, componentName) => {
        for (let i = 0; i < props[propName].length; i++) {
            if (!(props[propName][i] instanceof File)) {
                return new Error(
                    `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Expected an array of File objects, but found an element that is not a File object.`
                );
            }
        }
        return null;
    }).isRequired,
    reset: PropTypes.func.isRequired,
};

FileDropzone.defaultProps = {};

export default FileDropzone;
