import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { ActionButton } from "../../components/UI";
import JobSummary from "./JobSummary";
import JobStepper from "./JobStepper";

import StyledJobStatus from "../../styles/features/applications/JobStatus.Styled";
import { withApplicationProvider } from "./ApplicationProvider";

function JobStatusViewer({
    status,
    projectId,
    project,
    jobInfo,
    job,
    isUrlUp,
    jobId,
}) {
    const isApplicationProtected = "authentication" in job;
    const [openAuthenticationDialog, setOpenAuthenticationDialog] =
        useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleCloseDialog = () => {
        setOpenAuthenticationDialog(false);
    };

    const handleOpenDialog = () => {
        setOpenAuthenticationDialog(true);
    };

    return (
        <StyledJobStatus className="JobStatus">
            <JobSummary
                status={status}
                job={job}
                jobInfo={jobInfo}
                isUrlUp={isUrlUp}
                onOpenDialog={handleOpenDialog}
                isApplicationProtected={isApplicationProtected}
            />
            <Dialog
                data-testid="authentication-dialog"
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start",
                    },
                }}
                open={openAuthenticationDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle>Application Authentication</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This feature has been temporarily disabled, please
                        email/slack us for feedback.
                    </DialogContentText>
                    <DialogActions>
                        <ActionButton
                            onClick={handleCloseDialog}
                            variant="outline"
                        >
                            Close
                        </ActionButton>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            <Box className="JobStepperContainer">
                <JobStepper job={job} />
            </Box>
        </StyledJobStatus>
    );
}

JobStatusViewer.propTypes = {
    status: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    project: PropTypes.shape({
        jobs: PropTypes.arrayOf(
            PropTypes.shape({
                cpu: PropTypes.string,
                ram: PropTypes.string,
                gpu: PropTypes.string,
            })
        ),
        type: PropTypes.string.isRequired,
    }),
    jobInfo: PropTypes.shape({
        id: PropTypes.string.isRequired,
        project_id: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
    }).isRequired,
    job: PropTypes.shape({
        projectName: PropTypes.string,
        summary: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
        status: PropTypes.string.isRequired,
        resources: PropTypes.shape({
            webservice: PropTypes.string,
            is_url_up: PropTypes.bool,
        }),
        authentication: PropTypes.shape({
            username: PropTypes.string,
            password: PropTypes.string,
        }),
        labels: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    isUrlUp: PropTypes.bool.isRequired,
    jobId: PropTypes.string.isRequired,
};

JobStatusViewer.defaultProps = {
    project: undefined,
};

export default withApplicationProvider(JobStatusViewer);
