interface Options {
    method: string;

    headers?: Headers;

    body?: any;
}

interface HTTPRequest {
    headers?: Headers;
    body?: any;
}

const prepareRequestOptions = (
    method: string,
    headers?: Headers,
    body?: any
): Options => {
    const options: Options = {
        method,
    };

    if (headers) {
        options.headers = headers;
    }

    if (body) {
        options.body = body;
    }

    return options;
};

const handleError = async (response: Response) => {
    let error = "";
    const text = await response.text();
    try {
        const data = JSON.parse(text);
        error = data;
    } catch (err) {
        error = text;
    }

    return Promise.resolve(error);
};

const post = async (
    url: string,
    { headers, body }: HTTPRequest
): Promise<Response> => {
    const postOptions = prepareRequestOptions("POST", headers, body);

    try {
        const response = await fetch(url, postOptions);

        if (!response.ok) {
            throw await handleError(response);
        }

        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const POST_STATUS = {
    UPLOADING: "uploading",
    PROCESSING: "processing",
    SUCCESS: "success",
    ERROR: "error",
} as const;
export type PostStatus = (typeof POST_STATUS)[keyof typeof POST_STATUS];
export interface IPostStatus {
    progress: number;
    status: PostStatus;
}
export type UpdatePostStatus = (status: IPostStatus) => void;

/**
 * Performs a POST request with progress updates.
 *
 * @param {string} url - The URL to send the POST request to.
 * @param {Object} options - The request options.
 * @param {Headers} options.headers - The headers to be sent with the request.
 * @param {FormData|Blob|ArrayBuffer} options.body - The body of the request.
 * @param {UpdatePostStatus} updatePostStatus - Callback function to handle status updates.
 *
 * @returns {Promise<string>} A promise that resolves with the server's response or rejects with an error.
 *
 * @throws {Error} If there's a network error or if the request fails.
 */
const postWithStatus = (
    url: string,
    { headers, body }: HTTPRequest,
    updatePostStatus: UpdatePostStatus
): Promise<string> =>
    new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        if (headers) {
            headers.forEach((value, key) => {
                xhr.setRequestHeader(key, value);
            });
        }

        // Handle upload progress event -> send to `onStatusUpdate` callback
        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;

                // Notify the user if its taking more than msBeforeStartNotif ms to upload.
                if (percentComplete === 100) {
                    updatePostStatus({
                        progress: 100,
                        status: POST_STATUS.PROCESSING,
                    });
                } else {
                    updatePostStatus({
                        progress: percentComplete,
                        status: POST_STATUS.UPLOADING,
                    });
                }
            }
        };

        // Handle response from server
        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                updatePostStatus({
                    progress: 0,
                    status: POST_STATUS.SUCCESS,
                });
                resolve(xhr.response);
            } else {
                updatePostStatus({
                    progress: 0,
                    status: POST_STATUS.ERROR,
                });
                reject(JSON.parse(xhr.response));
            }
        };

        // Handle network error
        xhr.onerror = function () {
            updatePostStatus({
                progress: 0,
                status: POST_STATUS.ERROR,
            });
            reject(new Error("Network Error"));
        };

        // Send the request
        xhr.send(body);
    });

const put = async (
    url: string,
    { headers, body }: HTTPRequest
): Promise<Response> => {
    const options = prepareRequestOptions("PUT", headers, body);

    if (headers) {
        options.headers = headers;
    }

    if (body) {
        options.body = body;
    }

    try {
        const response = await fetch(url, options);

        if (!response.ok) {
            throw await handleError(response);
        }

        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
};

const patch = async (
    url: string,
    { headers, body }: HTTPRequest
): Promise<Response> => {
    const options = prepareRequestOptions("PATCH", headers, body);

    if (headers) {
        options.headers = headers;
    }

    if (body) {
        options.body = body;
    }

    try {
        const response = await fetch(url, options);

        if (!response.ok) {
            throw await handleError(response);
        }

        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
};

const get = async (url: string, headers?: Headers): Promise<Response> => {
    const options = prepareRequestOptions("GET", headers);

    try {
        const response = await fetch(url, options);

        if (!response.ok) {
            throw await handleError(response);
        }

        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
};

const del = async (
    url: string,
    { headers, body }: HTTPRequest
): Promise<Response> => {
    const postOptions = prepareRequestOptions("DELETE", headers, body);

    try {
        const response = await fetch(url, postOptions);

        if (!response.ok) {
            throw await handleError(response);
        }

        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
};

export default { post, postWithStatus, put, get, del, patch };
