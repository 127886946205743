import React, { useRef, useContext } from "react";
import "./css/style.css";
import PropTypes from "prop-types";
import { AppContext } from "../../../../context/AppContext";

function PasswordRecoveryView({ onSuccess, onFailure }) {
    const emailInputRef = useRef(null);
    const appContext = useContext(AppContext);
    const { accountContext } = appContext;
    const { forgotPassword } = accountContext;
    const handlePasswordRecovery = (e) => {
        e.preventDefault();

        const email = emailInputRef.current.value;
        forgotPassword(email)
            .then((result) => {
                onSuccess(email);
            })
            .catch((err) => {
                onFailure(err);
            });
    };

    return (
        <section className="main-content">
            <div className="login-card signin-card">
                <div className="registration-section">
                    <h1 className="form-header playfair-font">
                        Forgot Password
                    </h1>
                    <form id="forgotForm" onSubmit={handlePasswordRecovery}>
                        <div className="input-group">
                            <input
                                ref={emailInputRef}
                                type="email"
                                id="emailForgot"
                                className="input-email"
                                placeholder="Email address"
                                required
                                data-testid="email"
                            />
                        </div>
                        <button
                            type="submit"
                            className="btn-next"
                            data-testid="submit"
                        >
                            Request Forgot Password Email
                        </button>
                        <p className="register-redirect">
                            Back to login
                            <a href="/signin"> Sign in now!</a>
                        </p>
                    </form>
                </div>
            </div>
        </section>
    );
}

PasswordRecoveryView.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
};

export default PasswordRecoveryView;
