import React, { createContext, useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import ploomberAPI from "../../services/ploomberAPI.ts";
import { UserType } from "../../models/enum.ts";
import communityUserConfiguration from "./configurations/community.json";
import proUserConfiguration from "./configurations/pro.json";
import teamsUserConfiguration from "./configurations/teams.json";
import adminUserConfiguration from "./configurations/admin.json";
import { setAccessToken } from "../../utils/utils.ts";

const AccountContext = createContext();
const DEFAULT_USER_TYPE = UserType.COMMUNITY.value;
const getUserSession = () => {
    const userSessionString = localStorage.getItem("ploomber_user_session");
    if (userSessionString !== undefined) {
        return JSON.parse(userSessionString);
    }
    return null;
};

function Account(props) {
    const { children } = props;
    const [authed, setAuthed] = useState(false);
    const [subscription, setSubscription] = useState();
    const [userType, setUserType] = useState(() => {
        const userSession = getUserSession();
        return (userSession && userSession.type) || DEFAULT_USER_TYPE;
    });

    const [resourcesInfo, setResourcesInfo] = useState();
    const [trialInfo, setTrialInfo] = useState();

    const updateTrialInfo = async () => {
        try {
            const newTrialInfo = await ploomberAPI.getTrialInfo();
            if (newTrialInfo.message === "success") {
                setTrialInfo(newTrialInfo);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const updateUserState = async () => {
        try {
            const userSession = await ploomberAPI.getUserInfo();
            const returnedUserType = userSession?._model?.type;
            localStorage.setItem(
                "ploomber_user_session",
                JSON.stringify(userSession._model)
            );
            setUserType(returnedUserType);
        } catch (err) {
            console.log(err);
        }
    };

    const updateSubscription = async () => {
        try {
            const sub = await ploomberAPI.getSubscription();
            setSubscription(sub);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (authed) {
            updateTrialInfo();
            updateUserState();
            updateSubscription();
        }
    }, [authed]);

    const getSession = async () => {
        const session = await new Promise((resolve, reject) => {
            try {
                const userSession = getUserSession();
                if (userSession) {
                    setAuthed(true);
                }
                resolve(userSession);
            } catch (err) {
                setAuthed(false);
                reject(err);
            }
        });

        return session;
    };

    const handleUserSessionOnAuth = async (token) => {
        setAccessToken(JSON.stringify(token));
        const userSession = await ploomberAPI.getUserInfo();
        localStorage.setItem(
            "ploomber_user_session",
            JSON.stringify(userSession._model)
        );
        setAuthed(true);
        setUserType(userSession?._model?.type);
        return Promise.resolve(userSession);
    };

    const authenticate = async (email, password) => {
        const token = await ploomberAPI.login(email, password);
        return handleUserSessionOnAuth(token);
    };

    const authenticateFromSocial = async (authCode, redirectURI) => {
        const token = await ploomberAPI.loginSocial(authCode, redirectURI);
        return handleUserSessionOnAuth(token);
    };

    const logout = () => {
        localStorage.clear();
        setAuthed(false);
    };

    const signUp = async (email, password) => {
        await ploomberAPI.signup(email, password);
        return Promise.resolve(true);
    };

    const restartProject = async (projectId) => {
        const resp = await ploomberAPI.restartProject(projectId);
        return Promise.resolve(resp);
    };

    const checkProjectStatus = async (projectId) => {
        const resp = await ploomberAPI.checkProjectStatus(projectId);
        return Promise.resolve(resp);
    };

    const forgotPassword = async (email) => {
        const resp = await ploomberAPI.forgotPassword(email);
        return Promise.resolve(resp);
    };

    const resendConfirmation = async (email) => {
        const resp = await ploomberAPI.resendConfirmation(email);
        return Promise.resolve(resp);
    };

    function getUserConfiguration(feature) {
        let userConfiguration;
        switch (userType || DEFAULT_USER_TYPE) {
            case UserType.COMMUNITY.value:
                userConfiguration = communityUserConfiguration;
                break;
            case UserType.PRO.value:
                userConfiguration = proUserConfiguration;
                break;
            case UserType.TEAMS.value:
                userConfiguration = teamsUserConfiguration;
                break;
            case UserType.ADMIN.value:
                userConfiguration = adminUserConfiguration;
                break;
            default:
                break;
        }
        return userConfiguration[feature];
    }

    function canUserDeployFramework(framework) {
        const restrictedFrameworks = getUserConfiguration(
            "restrictedFrameworks"
        );
        return !restrictedFrameworks.includes(framework);
    }

    function canUserAccessComponent(componentId) {
        const userAllowedFeatures = getUserConfiguration("allowedFeatures");
        return userAllowedFeatures.includes(componentId);
    }

    const value = useMemo(
        () => ({
            signUp,
            restartProject,
            checkProjectStatus,
            forgotPassword,
            resendConfirmation,
            setAuthed,
            authed,
            authenticate,
            authenticateFromSocial,
            getSession,
            logout,
            userType,
            setUserType,
            trialInfo,
            setTrialInfo,
            getUserConfiguration,
            canUserAccessComponent,
            canUserDeployFramework,
            resourcesInfo,
            setResourcesInfo,
            subscription,
            setSubscription,
        }),
        [authed, userType, trialInfo, resourcesInfo, subscription]
    );

    return (
        <AccountContext.Provider value={value}>
            {children}
        </AccountContext.Provider>
    );
}

Account.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]).isRequired,
};

export { Account, AccountContext, getUserSession };
