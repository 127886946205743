import React from "react";
import PropTypes from "prop-types";
import SelectController from "./SelectHardwareController"; // Import the generalized component
import shouldDisplayPricing from "../../user/configurations/constraints";

function SelectRAMController({ ramOptions, onChange, featureId, value }) {
    return (
        <div data-testid="ram-select">
            <SelectController
                optionsData={ramOptions}
                onChange={onChange}
                featureId={featureId}
                value={value}
                labelText="Amount of RAM"
                unitText="GB"
                shouldDisplayPricePerHour={(hasApps, val) =>
                    shouldDisplayPricing("ram", hasApps, val)
                }
            />
        </div>
    );
}

SelectRAMController.propTypes = {
    ramOptions: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
        )
    ),
    onChange: PropTypes.func.isRequired,
    featureId: PropTypes.string.isRequired,
    value: PropTypes.string,
};

SelectRAMController.defaultProps = {
    ramOptions: [],
    value: undefined,
};

export default SelectRAMController;
