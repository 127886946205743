import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-material.css";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledSettingsGridLayout = styled(Grid)(() => ({
    "& .ag-theme-material": {
        height: "100%",
        width: "100%",
        marginBottom: 50,
    },
    "& .LoaderContainer": {
        width: 20,
        marginTop: -100,
    },
    "& .Title": {
        marginTop: 10,
    },
}));

export default StyledSettingsGridLayout;
