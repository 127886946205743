import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
} from "@mui/material";
import ploomberAPI from "../services/ploomberAPI.ts";
import { AppContext } from "../context/AppContext";
import { parseErrorMessage } from "../utils/utils.ts";

function DeleteProjectDialog({
    projectName,
    projectId,
    open,
    onClose,
    onProjectDeleteComplete,
}) {
    const [openDialog, setOpenDialog] = useState(open);
    const [projectJobs, setProjectJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { navigate, updateSnackbarStatus } = useContext(AppContext);

    useEffect(() => {
        if (openDialog) {
            ploomberAPI
                .getUserProject(projectId)
                .then((project) => setProjectJobs(project.jobs));
        }
    }, [openDialog]);

    useEffect(() => {
        setOpenDialog(open);
    }, [open]);

    const handleDeleteProject = async () => {
        try {
            setIsLoading(true);
            await ploomberAPI.deleteProject(projectId);
            setIsLoading(false);
            setOpenDialog(false);
            updateSnackbarStatus({
                message: "Project deleted successfully",
            });
        } catch (err) {
            updateSnackbarStatus({
                message: parseErrorMessage(err),
                severity: "error",
            });
            setIsLoading(false);
        } finally {
            if (onProjectDeleteComplete) {
                onProjectDeleteComplete();
            }
        }
    };

    return (
        <Dialog
            open={openDialog}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            style={{
                height: "60%",
            }}
        >
            <DialogTitle id="responsive-dialog-title">
                Delete <strong>{projectName ?? projectId}</strong>
            </DialogTitle>
            <DialogContent>
                <DialogContentText component="div" color="#000">
                    {/* TODO: enable this when version-control is provided */}
                    {/* Ref: https://github.com/ploomber/cloud-backend/issues/270#issuecomment-1745354604 */}
                    {/* Jobs that will be deleted
                    <div>
                        {projectJobs.length > 0 ? (
                            <ul>
                                {projectJobs.map((job) => (
                                    <li key={job.id}>{job.id}</li>
                                ))}
                            </ul>
                        ) : (
                            <div>No jobs found in this project</div>
                        )}
                    </div> */}
                    <div style={{ marginTop: 20 }}>
                        Are you sure you want to delete this project?
                        {/* TODO: enable this when version-control is provided */}
                        {/* Ref: https://github.com/ploomber/cloud-backend/issues/270#issuecomment-1745354604 */}
                        {/* {projectJobs.length > 0
                            ? `Are you sure you want to delete this project and its corresponding jobs (${projectJobs.length})?`
                            : "Are you sure you want to delete this project?"} */}
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    variant="contained"
                    onClick={handleDeleteProject}
                    color="error"
                    disabled={isLoading}
                >
                    Delete
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            style={{ marginLeft: "10px" }}
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DeleteProjectDialog.propTypes = {
    projectName: PropTypes.string,
    projectId: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onProjectDeleteComplete: PropTypes.func,
};

DeleteProjectDialog.defaultProps = {
    projectName: null,
    onProjectDeleteComplete: () => {},
};

export default DeleteProjectDialog;
