/* eslint-disable react/no-unused-class-component-methods */
import React, { useContext } from "react";
import "./css/style.css";
import PropTypes from "prop-types";
import { AppContext } from "../../../../context/AppContext";
import telemetry from "../../../../services/telemetry.ts";
import { parseErrorMessage } from "../../../../utils/utils.ts";

function VerifyEmailView(props) {
    const { email, onVerifyAccount } = props;
    const appContext = useContext(AppContext);
    const { accountContext, updateSnackbarStatus } = appContext;
    const { resendConfirmation } = accountContext;

    const handleVerifyAccount = (e) => {
        e.preventDefault();
        onVerifyAccount();
    };

    const handleResendConfirmation = (e) => {
        e.preventDefault();
        resendConfirmation(email)
            .then(() => {
                updateSnackbarStatus({
                    message: "Check your mailbox to verify the account",
                });
            })
            .catch((err) => {
                updateSnackbarStatus({
                    message: parseErrorMessage(err),
                    severity: "error",
                });
            });
        telemetry.log(
            `User ${email} requested for resending the confirmation email`
        );
    };

    return (
        <section className="main-content" style={{ paddingBottom: 0 }}>
            <div className="login-card signin-card">
                <div className="registration-section">
                    <div style={{ textAlign: "center" }}>
                        <h1 className="form-header playfair-font">
                            Verify your email address
                        </h1>
                        <p style={{ marginTop: 20, marginBottom: 20 }}>
                            A verification email has been sent to{" "}
                            <strong>{email}</strong>. <br />
                            Please check your mailbox to verify your account
                            before signing in.
                        </p>
                    </div>

                    <form id="verifyForm" onSubmit={handleVerifyAccount}>
                        <input
                            type="submit"
                            className="btn-next"
                            value="Sign in"
                            data-testid="signin-button"
                        />
                    </form>

                    <p className="register-redirect">
                        Did not receive the email?
                        <a
                            href="#0"
                            onClick={handleResendConfirmation}
                            data-testid="resend-link"
                        >
                            {" "}
                            Resend
                        </a>
                    </p>
                </div>
            </div>
        </section>
    );
}

VerifyEmailView.propTypes = {
    email: PropTypes.string.isRequired,
    onVerifyAccount: PropTypes.func.isRequired,
};

export default VerifyEmailView;
