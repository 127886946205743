import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import chainlitIcon from "../../../../assets/frameworks/chainlit.png";
import dashIcon from "../../../../assets/frameworks/dash.png";
import streamlitIcon from "../../../../assets/frameworks/streamlit.png";
import dockerIcon from "../../../../assets/frameworks/docker.svg";
import flaskIcon from "../../../../assets/frameworks/flask.png";
import panelIcon from "../../../../assets/frameworks/panel.png";
import shinyIcon from "../../../../assets/frameworks/shiny-r.png";
import voilaIcon from "../../../../assets/frameworks/voila.svg";
import solaraIcon from "../../../../assets/frameworks/solara.png";

const images = [
    chainlitIcon,
    dashIcon,
    streamlitIcon,
    dockerIcon,
    flaskIcon,
    panelIcon,
    shinyIcon,
    voilaIcon,
    solaraIcon,
];

const imageStyles = {
    [dockerIcon]: { height: "50%", marginTop: "2.7rem" },
    [shinyIcon]: { height: "50%", marginTop: "2.7rem" },
    [voilaIcon]: { height: "60%", marginTop: "1.2rem" },
};

export default function RegisterDemo() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(
                (prevIndex) => (prevIndex + 1) % images.length
            );
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        // Preload images
        images.forEach((image) => {
            const img = new Image();
            img.src = image;
        });
    }, []);

    const currentImage = images[currentImageIndex];
    const currentStyle = imageStyles[currentImage] || {};

    return (
        <div className="register-demo">
            <h2 className="register-demo__title">
                Deploy any framework within minutes
            </h2>
            <div className="register-demo__image-container">
                <AnimatePresence>
                    <motion.img
                        key={currentImageIndex}
                        src={currentImage}
                        style={currentStyle}
                        alt={`Framework Icon ${currentImageIndex + 1}`}
                        className="register-demo__image"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.7 }}
                    />
                </AnimatePresence>
            </div>
        </div>
    );
}
