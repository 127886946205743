import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import StyledSettingsGridLayout from "../../../styles/features/settings/SettingsGridLayout.Styled";

function SettingsGridLayout({ children, actions, title, loading, id }) {
    return (
        <StyledSettingsGridLayout container spacing={2} id={id}>
            <Grid item xs={6} container justifyContent="space-between">
                <h2 className="Title">{title}</h2>
                <div className="Actions">{actions}</div>
            </Grid>
            <Grid item xs={6}>
                {/* Empty */}
            </Grid>

            <Grid item xs={6}>
                <div
                    className="ag-theme-material"
                    data-testid="userDomainsGrid"
                >
                    {children}
                </div>
            </Grid>
            <Grid item xs={6}>
                {/* Empty */}
            </Grid>

            {/* Loading */}
            <Grid xs={6} item align="center">
                <div className="LoaderContainer">
                    {loading && <CircularProgress size={20} />}
                </div>
            </Grid>
            <Grid item xs={6}>
                {/* Empty */}
            </Grid>
        </StyledSettingsGridLayout>
    );
}

SettingsGridLayout.propTypes = {
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    actions: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]).isRequired,
    id: PropTypes.string.isRequired,
};

export default SettingsGridLayout;
