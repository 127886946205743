import React, { useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BlockContext from "../../context/BlockContext";

function BlockHeader({ title, description, expandable, actions, collapse }) {
    const { openBlock, setOpenBlock } = useContext(BlockContext);

    const toggleBlockDisplay = () => setOpenBlock(!openBlock);

    if (collapse) {
        useContext(false);
    }

    const render = () => {
        const toRender = openBlock ? <ArrowDropDownIcon /> : <ArrowRightIcon />;

        return (
            <div
                role="button"
                onClick={toggleBlockDisplay}
                onKeyPress={(e) => {}}
                tabIndex="0"
                style={{ visibility: expandable ? "visible" : "hidden" }}
            >
                {toRender}
            </div>
        );
    };
    return (
        <div className={`Header ${openBlock ? "" : "closed"}`}>
            <Box sx={{ flexGrow: 1 }}>
                <Box className="TitleWrapper">
                    {render()}
                    <div className="Title">{title}</div>
                </Box>

                <p className="Description">{description}</p>
            </Box>
            <Box className="Actions">{actions.map((action) => action)}</Box>
        </div>
    );
}

BlockHeader.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    expandable: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.node),
    collapse: PropTypes.bool,
};

BlockHeader.defaultProps = {
    description: "",
    expandable: true,
    actions: [],
    collapse: false,
};

export default BlockHeader;
