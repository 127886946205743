import React from "react";
import PropTypes from "prop-types";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import CircularProgress from "@mui/material/CircularProgress";
import StyledUserInstruction from "../styles/components/UserInstruction.Styled";

function UserInstruction({ instructionType, instruction, id }) {
    const renderInstructionIcon = () => {
        const iconStyle = { fontSize: "2em" }; // Adjust the font size as needed
        if (instructionType === "success") {
            return <CheckCircleIcon color="success" style={iconStyle} />;
        }
        if (instructionType === "error") {
            return <ErrorIcon color="error" style={iconStyle} />;
        }
        if (instructionType === "warning") {
            return <WarningIcon color="warning" style={iconStyle} />;
        }
        if (instructionType === "empty") {
            return <span />;
        }
        if (instructionType === "loading") {
            // circular progress size is controlled by the size prop
            return <CircularProgress color="primary" size={20} />;
        }

        return <InfoIcon color="info" style={iconStyle} />;
    };

    return (
        <StyledUserInstruction data-testid={id}>
            <span>{renderInstructionIcon()}</span>
            <p>{instruction}</p>
        </StyledUserInstruction>
    );
}

UserInstruction.propTypes = {
    instructionType: PropTypes.oneOf([
        "success",
        "error",
        "info",
        "warning",
        "loading",
    ]),
    instruction: PropTypes.string.isRequired,
    id: PropTypes.string,
};

UserInstruction.defaultProps = {
    id: null,
    instructionType: "info",
};

export default UserInstruction;
