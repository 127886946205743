/* eslint-disable react/no-unused-class-component-methods */
import React, { useRef, useContext } from "react";
import "./css/style.css";
import PropTypes from "prop-types";
import ploomberLogo from "./images/logo.svg";
import { AccountContext } from "../../../../features/user/Account";
import SocialLogin from "../../../../components/SocialLogin";
import { togglePasswordVisibility } from "../../../../utils/uiUtils.ts";
import { AppContext } from "../../../../context/AppContext";

function SigninView({ onSuccess, onFailure }) {
    const emailInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const accountContext = useContext(AccountContext);
    const appContext = useContext(AppContext);

    const handleSubmitSignIn = (e) => {
        const { authenticate } = accountContext;

        e.preventDefault();
        const email = emailInputRef.current.value;
        const password = passwordInputRef.current.value;

        authenticate(email, password)
            .then(async (data) => {
                const { initUser } = appContext;
                await initUser();
                onSuccess();
            })
            .catch((err) => {
                onFailure(err);
            });
    };

    return (
        <section className="main-content">
            <div className="login-card signin-card">
                <div className="registration-section">
                    <div className="main-title">
                        <a href="https://ploomber.io">
                            <img
                                className="logo"
                                src={ploomberLogo}
                                alt="Ploomber logo"
                            />
                        </a>
                    </div>
                    <h1 className="form-header playfair-font">Sign In</h1>
                    <p className="disclaimer">
                        By signing up you agree with our
                        <a
                            href="https://ploomber.io/terms-and-conditions"
                            style={{ marginLeft: 3, marginRight: 3 }}
                        >
                            terms of service
                        </a>
                        and
                        <a
                            href="https://ploomber.io/terms-and-conditions/#Privacy%20Policy"
                            style={{ marginLeft: 3 }}
                        >
                            privacy policy
                        </a>
                        .
                    </p>

                    {/* Start of 3rd party authenticators */}
                    <SocialLogin onSuccess={onSuccess} onFailure={onFailure} />

                    <div className="or-legend">
                        <div className="vertical-line" />
                        <span className="or-text">or</span>
                        <div className="vertical-line" />
                    </div>

                    <form id="registrationForm" onSubmit={handleSubmitSignIn}>
                        <div className="input-group">
                            <input
                                data-testid="email"
                                ref={emailInputRef}
                                type="email"
                                id="emailInputSignin"
                                className="input-email"
                                placeholder="Email address"
                                autoComplete="on"
                                required
                            />
                        </div>
                        <div className="input-group">
                            <input
                                data-testid="password"
                                ref={passwordInputRef}
                                type="password"
                                className="input-email"
                                placeholder="Password"
                                pattern=".*"
                                id="passwordInputSignin"
                                required
                            />

                            <i
                                className="far fa-eye"
                                id="togglePasswordForgot"
                                role="button"
                                onKeyPress={(e) => {}}
                                tabIndex="0"
                                onClick={(e) => {
                                    togglePasswordVisibility(passwordInputRef);
                                }}
                                aria-label="Toggle password"
                            />
                        </div>
                        <input
                            id="signInButton"
                            data-testid="submit"
                            type="submit"
                            className="btn-next"
                            value="Sign in"
                        />
                        <p className="register-redirect">
                            Need an account?
                            <a href="/register"> Sign up now!</a>
                        </p>
                        <p className="forgot-password-redirect">
                            Forgot password?
                            <a href="/forgot-password"> Reset password</a>
                        </p>
                    </form>
                </div>
            </div>
        </section>
    );
}

SigninView.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
};

export default SigninView;
