import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Box,
    Paper,
    Typography,
    LinearProgress,
    IconButton,
    Divider,
    CircularProgress,
} from "@mui/material";
import {
    Close as CloseIcon,
    Description as FileIcon,
    CheckCircle as SuccessIcon,
    Error as ErrorIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { POST_STATUS as UPLOAD_STATUS } from "../services/httpRequest.ts";

/*
 * Track upload progress of a file. Best to use with ../services/httpRequest.ts `postWithStatus` method.
 */
function UploadProgress({ title, progress, status }) {
    const [isVisible, setIsVisible] = useState(false);
    const [isMinimized, setIsMinimized] = useState(false);
    const [startTime, setStartTime] = useState(Date.now());
    const [elapsedSeconds, setElapsedSeconds] = useState(0);

    const canCloseStatuses = new Set([
        UPLOAD_STATUS.SUCCESS,
        UPLOAD_STATUS.ERROR,
    ]);

    const handleClose = () => {
        // Can't close while processing
        if (progress < 100 && !canCloseStatuses.has(status)) return;
        setIsVisible(false);
    };

    const handleToggleMinimize = () => {
        setIsMinimized(!isMinimized);
    };

    useEffect(() => {
        if (!isVisible && progress > 0) {
            // Show on upload
            setIsVisible(true);
            setStartTime(Date.now());
        } else if (status === UPLOAD_STATUS.SUCCESS) {
            // Hide after 10 seconds on success
            setTimeout(() => {
                setIsVisible(false);
            }, 10000);
        } else if (progress < 100) {
            setElapsedSeconds(Math.floor((Date.now() - startTime) / 1000));
        }
    }, [progress]);

    if (!isVisible) return null;

    const getStatusIcon = () => {
        switch (status) {
            case UPLOAD_STATUS.PROCESSING:
                return <CircularProgress size={16} />;
            case UPLOAD_STATUS.SUCCESS:
                return <SuccessIcon sx={{ color: "green" }} />;
            case UPLOAD_STATUS.ERROR:
                return <ErrorIcon sx={{ color: "red" }} />;
            default:
                return null;
        }
    };

    const getTitle = () => {
        if (status === UPLOAD_STATUS.PROCESSING) return "Processing...";
        if (status === UPLOAD_STATUS.SUCCESS) return "Upload successful";
        if (status === UPLOAD_STATUS.ERROR) return "Upload failed";
        return title;
    };

    return (
        <Box
            sx={{
                position: "fixed",
                bottom: 0,
                right: 16,
                zIndex: 9999,
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    padding: 2,
                    width: 480,
                    backgroundColor: "background.paper",
                }}
            >
                <Box
                    height={isMinimized ? 16 : 24}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            maxWidth: "80%",
                        }}
                    >
                        {getStatusIcon()}
                        <Typography variant="body1" noWrap sx={{ ml: 1 }}>
                            {getTitle()}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        {isMinimized && status === UPLOAD_STATUS.UPLOADING && (
                            <Typography variant="body2" sx={{ mr: 1 }}>
                                {progress.toFixed(2)}%
                            </Typography>
                        )}
                        <IconButton size="small" onClick={handleToggleMinimize}>
                            {isMinimized ? (
                                <ExpandMoreIcon fontSize="small" />
                            ) : (
                                <ExpandLessIcon fontSize="small" />
                            )}
                        </IconButton>
                        {canCloseStatuses.has(status) && (
                            <IconButton size="small" onClick={handleClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Box>
                </Box>
                {!isMinimized && (
                    <>
                        <Divider sx={{ my: 1 }} />
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <FileIcon sx={{ mr: 1 }} />
                            <Box sx={{ flexGrow: 1, mr: 1 }}>
                                <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                mx: 0.7,
                                mt: 0.5,
                            }}
                        >
                            {elapsedSeconds > 0 && (
                                <Typography variant="body2" align="left">
                                    {`${Math.floor(elapsedSeconds / 60)}m ${
                                        elapsedSeconds % 60
                                    }s`}
                                </Typography>
                            )}
                            <Box sx={{ flexGrow: 1 }} />
                            {!isMinimized && (
                                <Typography variant="body2" sx={{ mr: 1 }}>
                                    {progress.toFixed(2)}%
                                </Typography>
                            )}
                        </Box>
                    </>
                )}
            </Paper>
        </Box>
    );
}

UploadProgress.propTypes = {
    title: PropTypes.string.isRequired,
    progress: PropTypes.number.isRequired,
    status: PropTypes.oneOf([
        UPLOAD_STATUS.UPLOADING,
        UPLOAD_STATUS.PROCESSING,
        UPLOAD_STATUS.SUCCESS,
        UPLOAD_STATUS.ERROR,
    ]).isRequired,
};

export default UploadProgress;
