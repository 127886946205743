/* eslint-disable prefer-arrow-callback */
import React, { useMemo, forwardRef } from "react";
import { AgGridReact } from "ag-grid-react";

import PropTypes from "prop-types";
import * as uuid from "uuid";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-material.css";
import { withApplicationProvider } from "../../features/applications/ApplicationProvider";

const Grid = forwardRef(function Grid(props, ref) {
    const {
        id,
        columnDefs,
        onRowClicked,
        onGridReady,
        paginationPageSize,
        cacheBlockSize,
        suppressPaginationPanel,
        enableCellTextSelection,
    } = props;
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    return (
        <div
            id={id}
            data-testid={`grid-${id}`}
            style={gridStyle}
            className="ag-theme-material"
        >
            <AgGridReact
                ref={ref}
                rowModelType="infinite"
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                infiniteInitialRowCount={0}
                onRowClicked={onRowClicked}
                cacheBlockSize={cacheBlockSize}
                maxBlocksInCache={cacheBlockSize}
                paginationPageSize={paginationPageSize}
                suppressPaginationPanel={suppressPaginationPanel}
                enableCellTextSelection={enableCellTextSelection}
                pagination
            />
        </div>
    );
});

Grid.propTypes = {
    columnDefs: PropTypes.arrayOf(PropTypes.shape).isRequired,
    onRowClicked: PropTypes.func,
    id: PropTypes.string,
    paginationPageSize: PropTypes.number.isRequired,
    cacheBlockSize: PropTypes.number.isRequired,
    onGridReady: PropTypes.func.isRequired,
    suppressPaginationPanel: PropTypes.bool,
    enableCellTextSelection: PropTypes.bool,
};

Grid.defaultProps = {
    id: uuid.v4(),
    suppressPaginationPanel: false,
    enableCellTextSelection: false,
    onRowClicked: undefined,
};

export default withApplicationProvider(Grid);
