import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { CircularProgress, InputAdornment, Link } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import ControllerLabel from "../../../components/UI/ControllerLabel";
import ploomberAPI from "../../../services/ploomberAPI.ts";
import { AppContext } from "../../../context/AppContext";
import { CustomError, parseErrorMessage } from "../../../utils/utils.ts";
import { AccountContext } from "../../user/Account";
import StyleTextFieldWithLoader from "../../../styles/components/UI/TextFieldWithLoader.Styled";

const MAX_LENGTH = 30;
const MIN_LENGTH = 4;

const FEATURE_ID = "setProjectName";

function SetProjectNameController({ customName, onChange }) {
    const { updateSnackbarStatus } = useContext(AppContext);
    const { canUserAccessComponent, userType } = useContext(AccountContext);
    const [disabled, setDisabled] = useState();
    const [inputValue, setInputValue] = useState("");
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get("referredProjectId")) {
            setDisabled(true);
        } else {
            const canAccess = canUserAccessComponent(FEATURE_ID);
            setDisabled(!canAccess);
        }
    }, [userType]);

    useEffect(() => {
        if (customName !== null) {
            setInputValue(customName);
        }
    }, [customName]);

    const [error, setError] = useState(false);
    const [inputIcon, setInputIcon] = useState();

    function validateProjectName(name) {
        if (name.length > MAX_LENGTH) {
            throw new CustomError(
                "Invalid name",
                `Name is too long (maxiumum ${MAX_LENGTH} characters)`
            );
        }

        if (name.length < MIN_LENGTH) {
            throw new CustomError(
                "Invalid name",
                `Name is too short (minimum ${MIN_LENGTH} characters)`
            );
        }

        const pattern = /^[a-zA-Z0-9](?!.*--)[a-zA-Z0-9-]*[a-zA-Z0-9]$/;

        if (!pattern.test(name)) {
            throw new CustomError(
                "Invalid name",
                `Please use only English letters, numbers, or hyphens.`
            );
        }
    }

    const handleInputBlur = async (e) => {
        e.target.value = e.target.value.trim();
        const { value } = e.target;
        setInputValue(value);

        if (!value) {
            setInputIcon();
            setError(false);
            onChange(value, true);
            return;
        }

        try {
            setInputIcon(<CircularProgress />);

            // Validate name length and characters
            validateProjectName(value);

            // Will raise an error if project name is taken
            await ploomberAPI.checkProjectNameAvailability(value);

            setError(false);

            // Call onChange everytime, regardless if name is valid or not
            onChange(value, true);

            setTimeout(() => {
                setInputIcon(<CheckCircleIcon color="success" />);
            }, 100);
        } catch (err) {
            setError(true);
            onChange(value, false);
            updateSnackbarStatus({
                message: parseErrorMessage(err),
                severity: "error",
            });
            setTimeout(() => {
                setInputIcon(<CancelIcon color="error" />);
            }, 100);
        } finally {
            setInputIcon();
        }
    };

    return (
        <div className="Controller" feature-id={FEATURE_ID}>
            <div
                feature-id={FEATURE_ID}
                style={{ display: "flex", alignItems: "center" }}
            >
                <ControllerLabel
                    text="Set an application name"
                    featureId={FEATURE_ID}
                    toolTipMsg="You're on a free plan, thus, a random name will be assigned."
                />
            </div>
            <div>
                <StyleTextFieldWithLoader
                    data-testid="set-project-name"
                    disabled={disabled}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value.toLowerCase());
                    }}
                    error={error}
                    placeholder="Set an application name"
                    onBlur={handleInputBlur}
                    InputProps={{
                        endAdornment: [
                            <InputAdornment
                                key={0}
                                data-testid="app-name-text-field-suffix"
                                position="end"
                            >
                                .ploomberapp.io
                            </InputAdornment>,
                            <InputAdornment key={1} position="end">
                                {inputIcon}
                            </InputAdornment>,
                        ],
                    }}
                />
                <FormHelperText data-testid="app-name-help-text">
                    {disabled
                        ? "Application names can't be set once an app is deployed."
                        : "Your application will be available at this URL."}
                </FormHelperText>
            </div>
        </div>
    );
}

SetProjectNameController.propTypes = {
    customName: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

SetProjectNameController.defaultProps = {
    customName: "",
};

export default SetProjectNameController;
