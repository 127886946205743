import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Stepper as MUIStepper, Tooltip } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CircularProgress from "@mui/material/CircularProgress";
import StyledStepper from "../styles/components/Stepper.Styled";

function Stepper({ steps, activeStep }) {
    return (
        <StyledStepper>
            <Box>
                <MUIStepper activeStep={activeStep}>
                    {steps.map((step) => {
                        const {
                            label,
                            tooltip,
                            isFailed,
                            id,
                            isShowingSpinning,
                            active,
                        } = step;

                        const stepIconProps =
                            isShowingSpinning && !isFailed
                                ? {
                                      error: "",
                                      active: "",
                                      completed: "",
                                  }
                                : {};
                        return (
                            <Tooltip key={label} title={tooltip}>
                                <Step
                                    active={active}
                                    sx={{
                                        "& .MuiStepLabel-root .Mui-active": {
                                            color: isFailed
                                                ? "primary.danger"
                                                : "primary.success",
                                            fontWeight: 600,
                                        },
                                    }}
                                >
                                    <StepLabel
                                        StepIconComponent={
                                            isShowingSpinning && !isFailed
                                                ? CircularProgress
                                                : null
                                        }
                                        StepIconProps={{
                                            size: 25,
                                            color: "inherit",
                                            "data-testid": `spinning-${id}`,
                                            ...stepIconProps,
                                        }}
                                    >
                                        {label}
                                    </StepLabel>
                                </Step>
                            </Tooltip>
                        );
                    })}
                </MUIStepper>
            </Box>
        </StyledStepper>
    );
}

Stepper.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape).isRequired,
    activeStep: PropTypes.number.isRequired,
};

export default Stepper;
