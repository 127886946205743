import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";
import StyledUpgradeBanner from "../../styles/components/Pricings/StyledUpgradeBanner.Styled";
import { UserType } from "../../models/enum.ts";
import { AccountContext } from "../../features/user/Account";

function UpgradeBanner() {
    const { userType, trialInfo } = useContext(AccountContext);
    const [hide, setHide] = useState(false);
    const [onTrial, setOnTrial] = useState(false);

    const handleUpgradeClick = () => {
        document.querySelector("[data-testid='upgradeButton']").click();
    };

    useEffect(() => {
        let onTrialUpdated = onTrial;
        if (trialInfo !== undefined) {
            onTrialUpdated = trialInfo.days_left >= 0;
            setOnTrial(onTrialUpdated);
        }
        setHide(userType !== UserType.COMMUNITY.value && !onTrialUpdated);
    }, [userType, trialInfo]);

    return (
        <StyledUpgradeBanner hide={hide} data-testid="upgrade-banner">
            <Box className="UpgradeBannerContainer">
                <Box className="UpgradeBannerCenterTextContainer">
                    {onTrial && (
                        <Box>
                            You have {trialInfo.days_left} days left on your
                            free trial.&nbsp;
                        </Box>
                    )}
                    <Box
                        data-testid="upgrade-button"
                        className="UpgradeButton"
                        onClick={handleUpgradeClick}
                    >
                        Upgrade now
                    </Box>
                </Box>
                <Box className="CloseUpgradeBannerContainer">
                    <IconButton
                        data-testid="close-upgrade-banner"
                        onClick={() => {
                            setHide(true);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
        </StyledUpgradeBanner>
    );
}

export default UpgradeBanner;
