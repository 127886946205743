import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AutoCompleteInput from "../../../../components/UI/Form/inputs/AutoCompleteInput";
import { isBareURL } from "../../../../utils/utils.ts";

export default function CustomDomainInput({
    onChange,
    value,
    label,
    domains,
    onValidationError,
    disabled,
}) {
    const [showInputError, setShowInputError] = useState(false);
    const [domainsNames, setDomainsNames] = useState([]);

    useEffect(() => {
        setDomainsNames(domains.map((d) => d.name));
    }, [domains]);

    const isValidUrl = (domain) => {
        const domainToCheck = domain;
        const isValid = isBareURL(domainToCheck);

        setShowInputError(!isValid);
        return isValid;
    };

    const handleBlur = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length === 0) {
            setShowInputError(false);
        }
    };

    const validateInput = () => {
        if (onValidationError) {
            const domain = domains.find((d) => d.name === value);
            const alreadyRegistered = domain
                ? domain.dns_records.length > 0
                : false;

            if (alreadyRegistered) {
                onValidationError(false);
                return;
            }

            if (!isValidUrl(value)) {
                onValidationError(false);
            }
        }
    };

    useEffect(() => {
        if (value.length > 0) {
            validateInput();
        }
    }, [value]);

    return (
        <AutoCompleteInput
            label={label}
            disabled={disabled}
            value={value}
            id="customDomainInput"
            options={domainsNames}
            onBlur={handleBlur}
            onChange={onChange}
            error={showInputError}
            placeholder="example.com"
        />
    );
}

CustomDomainInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    domains: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            dns_records: PropTypes.arrayOf(
                PropTypes.shape({
                    type: PropTypes.string,
                    host: PropTypes.string,
                    value: PropTypes.string,
                })
            ),
        })
    ).isRequired,
    onValidationError: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};

CustomDomainInput.defaultProps = {
    label: undefined,
};
