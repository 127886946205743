import ErrorIcon from "@mui/icons-material/Error";
import React from "react";
import PropTypes from "prop-types";

function TerminalError({ className, message }) {
    return (
        <div className={className}>
            <div className="TerminalErrorContainer">
                <div className="TerminalErrorContainer2">
                    <div className="TerminalErrorContainer3">
                        <ErrorIcon color="error" />
                    </div>
                    {message}
                </div>
            </div>
        </div>
    );
}

TerminalError.propTypes = {
    className: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
};

TerminalError.defaultProps = {
    className: "",
};

export default TerminalError;
